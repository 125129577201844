export const Tags = {
    JAVASCRIPT: { display: 'javascript', styles: { backgroundColor: '#F0DB4F' }},
    TYPESCRIPT: { display: 'typescript', styles: { backgroundColor: '#2775C4' }},
    ANGULAR: { display: 'angular', styles: { backgroundColor: '#FF3E3E' }},
    REACT: { display: 'react', styles: { backgroundColor: '#5AD9F9' }},
    REGEX: { display: 'regex', styles: { }},
    GATSBY: { display: 'gatsby', styles: { backgroundColor: '#663399' }},
    GRAPHQL: { display: 'graphql', styles: { backgroundColor: '#E34297' }},
    COMPSCI: { display: 'compsci', styles: { }},
    HEROKU: { display: 'heroku', styles: { backgroundColor: '#9E7CC1' }},
    EXPRESSJS: { display: 'expressjs', styles: { backgroundColor: '#689F62' }},
    PYTHON: { display: 'python', styles: { backgroundColor: '#417FB0' }},
    MACHINELEARNING: { display: 'machine-learning', styles: { }},
    DATAMODELING: { display: 'data-modeling', styles: { }},
    KERAS: { display: 'keras', styles: { backgroundColor: '#FF3E3E' }},
    FIREBASE: { display: 'firebase', styles: { backgroundColor: '#F9CB47' }},
    UI: { display: 'ui', styles: { }},
    SASS: { display: 'sass', styles: { backgroundColor: '#cc6599' }},
}